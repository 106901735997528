import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  catchError,
  combineLatest,
  combineLatestWith,
  filter,
  iif,
  map,
  of,
  ReplaySubject,
  shareReplay,
  startWith,
  switchMap,
} from 'rxjs';
import { Member } from '@itk/universal-django-api';
import { CommonModule } from '@angular/common';
import { ItkMemberService } from '../services/member.service';
import { ActivatedRoute, RouterLink } from '@angular/router';

@Component({
  selector: 'itk-logo',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './logo.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItkLogoComponent {
  constructor(
    private readonly member: ItkMemberService,
    private readonly router: ActivatedRoute,
  ) {}

  private readonly excludeAgencyLogo$ = new ReplaySubject<boolean>(1);

  @Input()
  public set excludeAgencyLogo(value: boolean) {
    this.excludeAgencyLogo$.next(value);
  }

  getLiteTokenInURL(): string {
    return this.router.snapshot.queryParamMap.get('token') ?? '';
  }

  private readonly logo$ = iif(
    () => this.getLiteTokenInURL() !== '',
    of(this.getLiteTokenInURL()).pipe(
      switchMap((token) =>
        this.member.getPublicInfo(token).then((x) => {
          if (x !== undefined && x !== null && 'agency_logo' in x) {
            return x.agency_logo;
          }

          return '';
        }),
      ),
    ),
    this.member.member$.pipe(
      filter((x): x is Member => !('error' in x)),
      map(
        (member) =>
          member?.agency_logo || member?.child_account_of?.agency_logo || '',
      ),
      catchError(() => of('')),
      startWith(''),
    ),
  ).pipe(
    startWith(''),
    shareReplay({ refCount: true, bufferSize: 1 })
  );

  private readonly useAgencyLogo$ = this.excludeAgencyLogo$.pipe(
    combineLatestWith(this.logo$),
    map(([excludeAgencyLogo, logo]) => ({
      excludeAgencyLogo: excludeAgencyLogo,
      logo: logo,
    })),
    map((state) => {
      if (state.excludeAgencyLogo) return false;
      return state.logo.length > 0;
    }),
  );

  public readonly vm$ = combineLatest({
    logo: this.logo$.pipe(startWith('')),
    useAgencyLogo: this.useAgencyLogo$.pipe(startWith(false)),
  });
}
